import React from "react";
import "./home.css";

export default function Home(){
    return(
        <div className="home">
            {/* <h1 className="name">Anima Hoitt</h1> */}
        </div>
        
    )
}